import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

const handleReopenLead = async ({ leadId }: { leadId: string }): Promise<void> => {
  await request(`${APP_CONFIG.apiURL}/admin/leads/${leadId}/reopen`, {
    method: 'PATCH',
  });
};
export const useReopenLead = (): {
  reopenLead: typeof handleReopenLead;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: reopenLead, isPending } = useMutation({
    mutationFn: handleReopenLead,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la reouverture du projet');
    },
  });

  return { reopenLead, isLoading: isPending };
};
