import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { loadable } from '@travauxlib/shared/src/utils/loadable';

import { DoneActivitiesTable } from './components/DoneActivities/Loadable';
import { FutureActivitiesTable } from './components/FutureActivities/Loadable';
import { PendingActivitiesTable } from './components/PendingActivities/Loadable';

export const { DashboardActivities: Index } = loadable(() => import('./index'), {
  fallback: <Loader isFullHeightScreen />,
});

export const Dashboard = {
  Index,
  PendingActivitiesTable,
  FutureActivitiesTable,
  DoneActivitiesTable,
};
