import React from 'react';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ClientAccount } from '@travauxlib/shared/src/types';

type Props = {
  firstCreatedClientAccount: ClientAccount;
  handleClose: () => void;
};

const ClientContactModal: React.FC<Props> = ({ firstCreatedClientAccount, handleClose }) => (
  <ModalContent
    isScrollable
    title="Contact"
    validateAction={{
      label: 'Fermer',
      onClick: handleClose,
    }}
  >
    <div className="flex flex-col gap-md mt-xxs text-b1 font-medium text-neutral-700">
      <div className="flex items-center">
        <Person className="w-lg h-lg mr-xxs" />
        <span>
          {firstCreatedClientAccount.firstName} {firstCreatedClientAccount.lastName}
        </span>
      </div>
      <div>
        <Phone className="w-lg h-lg mr-xxs" />
        <span>{firstCreatedClientAccount.phoneNumber}</span>
      </div>
      <div>
        <Envelope className="w-lg h-lg mr-xxs" />
        <span>{firstCreatedClientAccount.email}</span>
      </div>
    </div>
  </ModalContent>
);

export const useOpenClientContactModal = (): ((
  firstCreatedClientAccount: ClientAccount,
) => void) => {
  const openModal = useOpenModal();

  return firstCreatedClientAccount =>
    openModal(ClientContactModal, {
      closable: true,
      title: 'Contact',
      firstCreatedClientAccount,
    });
};
