import { FC } from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

type Props = {
  handleClose: () => void;
};
export const ReopenedLeadModal: FC<Props> = ({ handleClose }) => (
  <FeedbackMessages
    handleClose={handleClose}
    variant="success"
    isOpen={true}
    title="Votre projet à été réouvert"
    validateAction={{
      label: 'Voir le projet',
      onClick: handleClose,
    }}
    message={
      <div className="text-b2 text-neutral-600">
        Votre projet est maintenant visible dans vos projets en cours.
      </div>
    }
  />
);
