import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  FicheProjetSubfeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class FicheProjetTrackingClass extends Tracker<AdminFeatures> {
  onFicheProjetViewed(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onFicheProjetInfoEdited(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Info,
      event: TrackingEvents.Edited,
      properties,
    });
  }

  onConsultationCreated(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Consultation,
      event: TrackingEvents.Created,
      properties,
    });
  }

  onConsultationViewed(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Consultation,
      event: TrackingEvents.Viewed,
      properties,
    });
  }

  onConsultationEdited(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Consultation,
      event: TrackingEvents.Edited,
      properties,
    });
  }

  onPrechiffrageCreated(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Prechiffrage,
      event: TrackingEvents.Created,
      properties,
    });
  }

  onConfigurationCreated(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Configuration,
      event: TrackingEvents.Created,
      properties,
    });
  }

  onDocumentAdded(properties: {
    [AdminEventsProperties.UserRole]?: string;
    [AdminEventsProperties.ProjetUUID]: string;
  }): void {
    this.sendTracking({
      subFeature: FicheProjetSubfeatures.Document,
      event: TrackingEvents.Added,
      properties,
    });
  }

  onActivitiesViewed(properties: { [AdminEventsProperties.ProjetUUID]: string }): void {
    this.sendTracking({
      event: TrackingEvents.Viewed,
      subFeature: FicheProjetSubfeatures.Activities,
      properties,
    });
  }
}

export const FicheProjetTracking = new FicheProjetTrackingClass(AdminFeatures.Consultations);
