import React from 'react';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { ProjectContext } from 'features/Project/api/useProjectContext';

import { HeaderActions } from './HeaderActions';

import { useOpenClientContactModal } from '../hooks/useOpenClientContactModal';

const baseTabs = [
  {
    label: 'Information',
    to: 'information',
  },
  {
    label: 'Cadrage',
    to: 'cadrage',
  },
  {
    label: 'Consultation',
    to: 'consultation',
  },
  {
    label: 'Devis',
    to: 'devis',
  },
  {
    label: 'Activités',
    to: 'activities',
  },
];

type Props = {
  projectContext: ProjectContext;
};

export const Header: React.FC<Props> = ({ projectContext }) => {
  const openClientContactModal = useOpenClientContactModal();
  const isTabletOrMobile = useIsTabletOrMobile();
  const { project, clientAccounts, chantiers, lead } = projectContext;
  const [firstCreatedClientAccount] = clientAccounts;

  return (
    <div className="bg-neutral-0 sm-desktop:-mx-xl -mx-md -mt-md sm-desktop:px-xl pl-md pr-sm sm-desktop:min-h-[9.5rem]">
      <div className="sm-desktop:pt-md pt-xs">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-md max-w-3/5">
            <div className="text-b1 tablet:text-h3 font-bold text-neutral-800 truncate">
              {project.title}
            </div>
            {lead?.lostReason && (
              <Tag
                className="font-medium hidden sm-desktop:block"
                variant="error"
                label="Perdu"
                size="lg"
              />
            )}
          </div>
          <HeaderActions projectContext={projectContext} />
        </div>
        <div className="flex gap-md mt-xxs text-b1 font-medium text-neutral-700">
          <div className="flex items-center">
            <Person className="sm-desktop:w-lg sm-desktop:h-lg mr-xxs w-md h-md" />
            <span>
              {firstCreatedClientAccount.firstName} {firstCreatedClientAccount.lastName}
            </span>
          </div>
          <div className="hidden sm-desktop:flex gap-md">
            <div>
              <Phone className="w-lg h-lg mr-xxs" />
              <span>{firstCreatedClientAccount.phoneNumber}</span>
            </div>
            <div>
              <Envelope className="w-lg h-lg mr-xxs" />
              <span>{firstCreatedClientAccount.email}</span>
            </div>
          </div>
          <Link
            className="sm-desktop:hidden"
            onClick={() => openClientContactModal(firstCreatedClientAccount)}
          >
            Contact
          </Link>
        </div>
      </div>
      <div className="flex text-b1 font-bold sm-desktop:flex-row flex-col-reverse sm-desktop:pt-md pt-xxs">
        <Tabs
          items={
            chantiers?.length
              ? [
                  ...baseTabs,
                  {
                    label: 'Chantier',
                    to: chantiers.length === 1 ? `chantier/${chantiers[0].slug}` : 'chantier',
                    isActive: location => location.pathname.includes('chantier'),
                  },
                ]
              : baseTabs
          }
          size={isTabletOrMobile ? 'sm' : 'md'}
          hideContainerBottomBorder
          className="flex-1"
        />
      </div>
    </div>
  );
};
