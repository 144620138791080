import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import InventoryOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InventoryOutline.svg?react';
import ManageAccountsUnfilled from '@travauxlib/shared/src/components/DesignSystem/assets/ManageAccountsUnfilled.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Pipedrive from '@travauxlib/shared/src/components/DesignSystem/assets/Pipedrive.svg?react';
import ProjectError from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectError.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { getProjectURL, useSignInAsClient } from 'api/useSignInAsClient';
import { useCloseLead } from 'features/Project/api/useCloseLead';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { useReopenLead } from 'features/Project/api/useReopenLead';

import { ClientAccountsModal } from './ClientAccountsModal';
import { CloseLeadModal } from './CloseLeadModal';
import { Comments } from './Comments';
import { ReopenedLeadModal } from './ReopenedLeadModal';

type Props = {
  projectContext: ProjectContext;
};

export const HeaderActions: React.FC<Props> = ({ projectContext }) => {
  const handleSignIn = useSignInAsClient();
  const { closeLead } = useCloseLead();
  const { reopenLead } = useReopenLead();
  const navigate = useNavigate();
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();
  const [isClientAccountsModalOpen, setIsClientAccountsModalOpen] = useState(false);
  const { project, clientAccounts, lead } = projectContext;
  const [firstCreatedClientAccount] = clientAccounts;

  const pipedriveActions = (isLeadClosed: boolean): ActionListItem[] => [
    {
      type: 'action',
      onClick: () => window.open(project.pipedriveDealUrl, '_blank'),
      label: 'Accéder à Pipedrive',
      icon: <Pipedrive />,
    },
    isLeadClosed
      ? {
          type: 'action',
          disabled: !project.leadId,
          onClick: () => {
            if (!!project.leadId) {
              reopenLead({ leadId: project.leadId }).then(() => {
                openModal(ReopenedLeadModal, {
                  closable: true,
                });
              });
            }
          },
          label: 'Réouvrir le projet',
          icon: <ProjectError />,
        }
      : {
          type: 'action',
          onClick: () => {
            if (!!project.leadId) {
              openModal(CloseLeadModal, {
                closable: true,
                onCloseLead: closeLead,
                leadId: project.leadId,
              });
            }
          },
          label: 'Marquer comme perdu',
          icon: <ProjectError />,
        },
  ];

  const dotsMenuItems: ActionListItem[] = [
    {
      type: 'action',
      onClick: () => navigate('actions'),
      label: 'Valider une action du client',
      icon: <InventoryOutline />,
    },
    {
      type: 'action',
      onClick: () => setIsClientAccountsModalOpen(true),
      label: 'Gestion des contacts',
      icon: <Person />,
    },
  ];

  return (
    <div className="flex ml-md items-center">
      <Comments project={project} />
      <Button
        className="sm-desktop:ml-md sm-desktop:mr-xs ml-xs mr-xxs whitespace-nowrap"
        onClick={() => handleSignIn(firstCreatedClientAccount.uuid, getProjectURL(project.uuid))}
        leftIcon={<ManageAccountsUnfilled />}
        children={isTabletOrMobile ? undefined : <>Accéder à l'espace client</>}
        size={isTabletOrMobile ? 'sm' : 'md'}
      />
      <ActionList
        items={
          project.pipedriveDealId
            ? dotsMenuItems.concat(pipedriveActions(!!lead?.lostReason))
            : dotsMenuItems
        }
        small={isTabletOrMobile}
        trigger={(toggle, referenceProps) => (
          <Button
            onClick={toggle}
            variant="tertiary"
            leftIcon={<DotsMenu />}
            size={isTabletOrMobile ? 'sm' : 'md'}
            {...referenceProps}
            data-testid="icon-button-container"
          />
        )}
      />
      <ClientAccountsModal
        isOpen={isClientAccountsModalOpen}
        projectContext={projectContext}
        onClose={() => setIsClientAccountsModalOpen(false)}
      />
    </div>
  );
};
