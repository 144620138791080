import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { loadable } from '@travauxlib/shared/src/utils/loadable';

import { DoneProjectActivities } from './components/DoneProjectActivities/Loadable';
import { PendingProjectActivities } from './components/PendingProjectActivities/Loadable';

export const { ProjectActivities: Index } = loadable(() => import('./index'), {
  fallback: <Loader isFullHeightScreen />,
});

export const ProjectActivities = {
  Index,
  PendingProjectActivities,
  DoneProjectActivities,
};
