import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECT_KEY } from './useProjectContext';

const handleCloseLead = async ({
  leadId,
  lostReason,
}: {
  leadId: string | undefined;
  lostReason: string;
}): Promise<void> => {
  await request(`${APP_CONFIG.apiURL}/admin/leads/${leadId}/close`, {
    method: 'PATCH',
    body: {
      lostReason,
    },
  });
};
export const useCloseLead = (): {
  closeLead: typeof handleCloseLead;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: closeLead, isPending } = useMutation({
    mutationFn: handleCloseLead,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEY] });
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la fermeture du projet');
    },
  });

  return { closeLead, isLoading: isPending };
};
