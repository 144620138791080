import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';

import BorderColorUnfilled from '@travauxlib/shared/src/components/DesignSystem/assets/BorderColorUnfilled.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { Project } from '@travauxlib/shared/src/types';
import { AdminEventsProperties } from '@travauxlib/shared/src/utils/tracking';

import { useUpdateProject } from 'features/Project/api/useUpdateProject';
import { NoteTracking } from 'utils/tracking/NoteTracking';

export type Props = {
  project: Project;
};

export const Comments: React.FC<Props> = ({ project }) => {
  const [openedComments, setOpenedComments] = useState(false);
  const isTabletOrMobile = useIsTabletOrMobile();
  const updateProject = useUpdateProject();
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => setOpenedComments(false));

  const handleFormSubmit = async (values: { comments: string }): Promise<void> => {
    await updateProject({ ...project, comments: values.comments });
    toast.success('Enregistré avec succès');
    setOpenedComments(false);
    NoteTracking.onNoteEdited({
      [AdminEventsProperties.ProjetUUID]: project.uuid,
    });
  };

  return (
    <>
      <Button
        onClick={() => setOpenedComments(openedComments => !openedComments)}
        variant="secondary"
        className="whitespace-nowrap"
        leftIcon={<BorderColorUnfilled />}
        children={isTabletOrMobile ? undefined : <>Voir la note</>}
        size={isTabletOrMobile ? 'sm' : 'md'}
      />
      <Form<{ comments: string }>
        onSubmit={handleFormSubmit}
        initialValues={{
          comments: project.comments,
        }}
      >
        {({ handleSubmit, dirty }) => (
          <>
            {isTabletOrMobile ? (
              <Modal
                isOpen={openedComments}
                title="Note sur le projet"
                handleClose={() => setOpenedComments(false)}
              >
                <ModalContent
                  title="Note sur le projet"
                  validateAction={{ label: 'Enregistrer', onClick: handleSubmit }}
                >
                  <TextareaField id="comments" name="comments" label="Ajouter une note" />
                </ModalContent>
              </Modal>
            ) : (
              <div
                className={classNames(
                  'fixed right-0 top-0 h-screen bg-neutral-0 z-30',
                  'pb-xl pr-sm pl-md pt-md',
                  'sm-desktop:w-[32rem]',
                  'shadow-left',
                  { hidden: !openedComments },
                )}
              >
                <div className="flex mb-md justify-between">
                  <div className="text-h5 font-bold">Note sur le projet</div>
                  <IconButton onClick={() => setOpenedComments(false)} type="button">
                    <CrossSymbol />
                  </IconButton>
                </div>
                <TextareaField
                  id="comments"
                  name="comments"
                  label="Ajouter une note"
                  rows={10}
                  maxRows={24}
                />
                <Button onClick={handleSubmit} fullwidth className="mt-xl" disabled={!dirty}>
                  Enregistrer
                </Button>
              </div>
            )}
          </>
        )}
      </Form>
    </>
  );
};
