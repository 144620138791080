import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AdminFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class NoteTrackingClass extends Tracker<AdminFeatures> {
  public onNoteEdited(properties: {
    [AdminEventsProperties.ProjetUUID]: string | undefined;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Edited,
      properties,
    });
  }
}

export const NoteTracking = new NoteTrackingClass(AdminFeatures.Note);
