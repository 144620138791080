import { FC, useState } from 'react';

import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { DropdownOption } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Option';
import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

import { lostReasonsMarketPlace } from 'utils/constants';

type Props = {
  onCloseLead: (payload: { lostReason: string; leadId: string }) => void;
  handleClose: () => void;
  leadId?: string;
};
export const CloseLeadModal: FC<Props> = ({ handleClose, onCloseLead, leadId }) => {
  const lostReasonOptions: DropdownOption<string>[] = lostReasonsMarketPlace;
  const [selectedLostReason, setSelectedLostReason] = useState<string | undefined>(undefined);

  return (
    <FeedbackMessages
      variant="warning"
      isOpen
      title="Vous souhaitez marquer le projet comme perdu ?"
      handleClose={handleClose}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
      validateAction={{
        label: 'Valider',
        onClick: () => {
          if (!!selectedLostReason && !!leadId) {
            onCloseLead({ lostReason: selectedLostReason, leadId });
            handleClose();
          }
        },
        disabled: !selectedLostReason,
      }}
      message={
        <div className="flex flex-col w-full">
          <div className="text-b2 text-neutral-600">
            En confirmant, votre projet sera archivé et marqué comme perdu. Pourquoi souhaitez-vous
            loster ce projet ?
          </div>
          <Dropdown
            id="closeLeadReason"
            className="mt-md"
            placeholder="Raison de la perte"
            options={lostReasonOptions}
            onChange={newLostReason => setSelectedLostReason(newLostReason)}
          />
        </div>
      }
    />
  );
};
