import convert from 'heic-convert/browser';

const convertFileToBuffer = (file: File): Promise<Buffer> =>
  new Promise(resolve => {
    const reader = new FileReader();
    const readFile = (): void => {
      const buffer = reader.result;
      resolve(buffer as Buffer);
    };

    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });

export const convertHeicFileToPng = async (file: File): Promise<File> => {
  const convertedFile = await convert({
    buffer: new Uint8Array(await convertFileToBuffer(file)),
    format: 'PNG',
  });
  return new File([new Blob([convertedFile])], file.name.replace(/hei(c|f)$/, 'png'));
};
